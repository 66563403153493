import React, { Component } from "react";
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from "@chargebee/chargebee-js-react-wrapper";
import "./Example4.css";

export default class Example4 extends Component {
  constructor(props) {
    super(props);
    // Create ref to assign card-component
    this.cardRef = React.createRef();

    this.state = {
      intent_id: "",
      error: "",
      wholeError: "",
      loading: false,
      firstName: "",
      lastName: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      countryCode: "GB",
      postCode: "",
      options: {
        // Custom classes - applied on container elements based on field's state
        classes: {
          focus: "focus",
          invalid: "invalid",
          empty: "empty",
          complete: "complete",
        },

        style: {
          // Styles for default field state
          base: {
            color: "#333",
            fontWeight: "500",
            fontFamily: "Roboto, Segoe UI, Helvetica Neue, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            ":focus": {
              color: "#424770",
            },

            "::placeholder": {
              color: "transparent",
            },

            ":focus::placeholder": {
              color: "#7b808c",
            },
          },

          // Styles for invalid field state
          invalid: {
            color: "#e41029",

            ":focus": {
              color: "#e44d5f",
            },
            "::placeholder": {
              color: "#FFCCA5",
            },
          },
        },

        // locale
        locale: "en",

        // Custom placeholders
        placeholder: {
          number: "4111 1111 1111 1111",
          expiry: "MM / YY",
          cvv: "CVV",
        },

        // Custom fonts
        fonts: ["https://fonts.googleapis.com/css?family=Roboto:300,500,600"],
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.authorizeWith3ds = this.authorizeWith3ds.bind(this);
  }

  urlEncode = (data) => {
    var str = [];
    for (var p in data) {
      if (
        data.hasOwnProperty(p) &&
        !(data[p] == undefined || data[p] == null)
      ) {
        str.push(
          encodeURIComponent(p) +
            "=" +
            (data[p] ? encodeURIComponent(data[p]) : "")
        );
      }
    }
    return str.join("&");
  };

  createIntent = async () => {
    return fetch("http://localhost:8000/api/generate_payment_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: this.urlEncode({
        amount: 3001,
        currency_code: "GBP",
        payment_method_type: "card",
        customer_id: "BTceR5TNv3tV61rB", //"01ftq1g8gk1jaxkFAKE92",
      }),
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log("error", err);
      });
  };

  authorizeWith3ds = async () => {
    this.setState({ loading: true, error: '', wholeError: '' });
    const { firstName,
      lastName,
      city,
      addressLine1,
      addressLine2,
      countryCode,
      postCode,
    } = this.state;

    // console.log("H-intent", intent);
    // Call authorizeWith3ds methods through  card component's ref
    this.cardRef.current
      .authorizeWith3ds(JSON.parse(this.state.intent), {
        billingAddress: {
          firstName: firstName || '.',
          lastName: lastName || '.',
          city: city || '.',
          addressLine1: addressLine1 || '.',
          addressLine2: addressLine2 || '.',
          countryCode: countryCode || 'GB',
          zip: postCode || '.',
        },
      })
      .then((data) => {
        this.setState({ loading: false, intent_id: data.id, error: "" });
        // Make an ajax GET request to localhost:9000:
        fetch("localhost:9000", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
      })
      .catch((error) => {
        console.log("authorizeWith3ds-error", JSON.stringify(error));

        this.setState({
          loading: false,
          intent_id: "",
          error: `Problem while tokenizing your card details, error code[${error?.code}]`,
          wholeError: error,
        });
      });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log("H-handleChange", name, value);
    if (name === "intent" && value) {
      console.log("H-intent object", JSON.parse(value));
      return this.setState({
        error: "",
        wholeError: "",
        [name]: value,
      });
    }
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { style, classes, locale, placeholder, fonts } = this.state.options;
    return (
      <div className="ex1 container">
        <div className="ex4-wrap">
          <div className="ex4-fieldset">
            <div className="ex4-field">
              <input
                name="intent"
                className={this.state.intent ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="Intent"
                value={this.state.intent}
                onChange={this.handleChange}
              />
              <label className="ex4-label">Raw Intent object</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="firstName"
                className={this.state.firstName ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="First name"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              <label className="ex4-label">First Name</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="lastName"
                className={this.state.lastName ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="Last name"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
              <label className="ex4-label">Last Name</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="addressLine1"
                className={this.state.addressLine1 ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="Address Line 1"
                value={this.state.addressLine1}
                onChange={this.handleChange}
              />
              <label className="ex4-label">Address Line 1</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="addressLine2"
                className={this.state.addressLine2 ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="Address Line 2"
                value={this.state.addressLine2}
                onChange={this.handleChange}
              />
              <label className="ex4-label">Address Line 2</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="city"
                className={this.state.city ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="City"
                value={this.state.city}
                onChange={this.handleChange}
              />
              <label className="ex4-label">City</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="countryCode"
                className={this.state.countryCode ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="GB"
                value={this.state.countryCode}
                onChange={this.handleChange}
              />
              <label className="ex4-label">2 Character Country Code (e.g. GB)</label>
              <i className="ex4-bar"></i>
            </div>
            <div className="ex4-field">
              <input
                name="postCode"
                className={this.state.postCode ? "ex4-input val" : "ex4-input"}
                type="text"
                placeholder="Post Code"
                value={this.state.postCode}
                onChange={this.handleChange}
              />
              <label className="ex4-label">Post Code</label>
              <i className="ex4-bar"></i>
            </div>

            {/* Pass all options as props to card component  */}
            {/* Assign ref to call internal methods */}
            <CardComponent
              ref={this.cardRef}
              className="fieldset field"
              styles={style}
              classes={classes}
              locale={locale}
              placeholder={placeholder}
              fonts={fonts}
            >
              <div className="ex4-field">
                {/* Card number component */}
                <CardNumber className="ex4-input" />
                <label className="ex4-label">Card Number</label>
                <i className="ex4-bar"></i>
              </div>

              <div className="ex4-fields">
                <div className="ex4-field">
                  {/* Card expiry component */}
                  <CardExpiry className="ex4-input" />
                  <label className="ex4-label">Expiry</label>
                  <i className="ex4-bar"></i>
                </div>

                <div className="ex4-field">
                  {/* Card cvv component */}
                  <CardCVV className="ex4-input" />
                  <label className="ex4-label">CVC</label>
                  <i className="ex4-bar"></i>
                </div>
              </div>
            </CardComponent>
          </div>
          <button
            type="submit"
            className={this.state.loading ? "submit ex4-button" : "ex4-button"}
            onClick={this.authorizeWith3ds}
          >
            Pay $x & authorizeWith3ds
          </button>
          {this.state.error && (
            <div className="error" role="alert">
              {this.state.error}
            </div>
          )}
          <br />
          {this.state.wholeError && (
            <div>
              <h4 style={{ color: "#000" }}>Whole Error Object</h4>
              <p
                className="error"
                style={{
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                }}
                role="alert"
              >
                {JSON.stringify(this.state.wholeError)}
              </p>
            </div>
          )}
          {this.state.intent_id && (
            <div className="intent_id">{this.state.intent_id}</div>
          )}
        </div>
      </div>
    );
  }
}

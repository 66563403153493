import React, { Component } from "react";
import Example4 from "./components/example4/Example4.js";

class App extends Component {
  constructor(props) {
    super(props);
    window.Chargebee.init({
      // site: "honeycomics-v3-test",
      // publishableKey: "test_qoH22RugUvm5IcxoqUD5Svdcu9mX5figf"
      site: "roczen-test",
      publishableKey: "test_6o51y0eIDS0PHFcuWSHewm5lBnIfgSYaB",
    });
  }

  render() {
    return <Example4 />;
  }
}

export default App;
